<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import ShipChart from '@/components/chart/ShipChart.vue'
import ShipTab from '@/components/ships/ShipTab.vue'
import { useApplicationStore } from '@/stores/application'
import { useShipTypeStore } from '@/stores/shipType'
import { useFairleadStore } from '@/stores/fairlead'

const shipStore = useShipStore()
const applicationStore = useApplicationStore()
const shipTypeStore = useShipTypeStore()
const fairleadStore = useFairleadStore()
const { items: ships, currentItem: currentShip } = storeToRefs(shipStore)

onMounted(async () => {
  await applicationStore.fetchItems()
  await shipTypeStore.fetchItems()
  await fairleadStore.fetchOptions()
  await shipStore.fetchOptions()
  await shipStore.fetchPaginationItems()
  if (ships.value.length) {
    shipStore.setCurrentItem(ships.value[0])
  }
})
</script>

<template>
  <v-layout full-height>
    <v-row no-gutters class="fill-height ma-0">
      <v-col cols="6" style="height: 99%">
        <ShipChart :ship="currentShip" chart-event="chartEvent" chart-ref="chart"></ShipChart>
      </v-col>
      <v-col class="pa-0 border-left-grey fill-height" cols="6">
        <ShipTab chart-event="chartEvent"></ShipTab>
      </v-col>
    </v-row>
  </v-layout>
</template>
