import BaseStore from '@rhdhv/vue-basestore'
import _ from 'lodash'
import type {
  BaseStoreActionType,
  BaseStoreGetterType,
  BaseStoreStateType
} from '@/stores/type/baseStore.type'

class AppBaseStore<T> extends BaseStore {
  appState: BaseStoreStateType<T>
  appActions: BaseStoreActionType<T>
  appGetters: BaseStoreGetterType<T>

  constructor(r: string, a: any, l = null, i: string = 'id') {
    super(r, a, l, i)
    this.appState = {
      ...this.state,
      error: false,
      options: {},
      a: a,
      r: r,
      i: i
    }
    this.appActions = {
      ...this.actions,
      fetchOptions: this.fetchOptions,
      postOrUpdate: this.postOrUpdate,
      setDeleteItem: this.setDeleteItem,
      update: this.update,
      addToProperty: this.addToProperty,
      fetchItemsAdd: this.fetchItemsAdd
    }
    this.appGetters = {
      ...this.getters,
      fieldsList(state): { [key: string]: any } {
        if (state.options.actions) {
          const writableFields = Object.entries(state.options.actions.POST)?.filter(
            ([, value]: [string, any]) => !value.read_only
          )
          return writableFields?.map((item: any) => {
            item[1].parameter = item[0]
            return item[1]
          })
        } else {
          return []
        }
      },
      fieldsFiltered(state): { [key: string]: any } {
        return this.fieldsList?.filter((field) => state.filterFields.includes(field.parameter))
      },
      fields(state): { [key: string]: any } {
        return state.options.actions?.POST
      },
      itemsAvailable(state): boolean {
        return state.items ? state.items.length > 0 : false
      },
      optionsAvailable(state): boolean {
        return !_.isEmpty(state.options)
      }
    }
  }

  async fetchOptions(): Promise<void> {
    try {
      this.fetchLoading = true
      const response = await this.a.service(this.r).options()
      if (response) {
        this.options = response.actions['POST']
      }
      this.fetchLoading = false
    } catch (e) {
      console.error(e)
      this.fetchLoading = false
    }
  }

  async fetchItemsAdd(filter: { [key: string]: number | string } = {}): Promise<T[] | boolean> {
    this.fetchLoading = true
    try {
      const response = await this.a.service(this.r).find({
        query: filter
      })
      this.items.push(...response)
    } catch (e) {
      this.setSnackbarMessage('Fetch items failed.', 'error')
      console.error(e)
      this.fetchLoading = false
      return false
    }
    this.fetchLoading = false
    return this.items
  }

  async postOrUpdate(item = null): Promise<T> {
    return this.currentItem.id
      ? await this.update(item, this.currentItem.id)
      : await this.create(item)
  }

  // TODO should be cleaned up better
  async update(item: T = null, id: number = null): Promise<T | false> {
    this.writeLoading = true
    const updateItem = item ? item : this.currentItem
    let responseData
    const idNumber = id ? id : updateItem[this.i]
    try {
      responseData = await this.a.service(this.r).patch(idNumber, updateItem)
      const index = this.items.map((item) => item[this.i]).indexOf(responseData[this.i])
      this.items[index] = responseData
    } catch (e) {
      this.writeLoading = false
      return false
    }
    this.writeLoading = false
    return responseData
  }

  // TODO refactor this
  addToProperty(value: any[], property: string): void {
    if (this.items[property]) {
      const ids = this.items[property].map((item) => item.id)
      const onlyNewItems = value.filter((item) => !ids.includes(item.id))
      this.items[property].push(...onlyNewItems)
    } else {
      this.items[property] = value
    }
  }

  setDeleteItem(e?: T = null): void {
    this.deleteItem = e
  }
}

export default AppBaseStore
