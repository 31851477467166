<script lang="ts" setup>
import FairleadTableRow from './FairleadTableRow.vue'
import type { FairleadType, PedestalType, ShipType, WinchType } from '@/stores/type/ship.type'
import type { PropType } from 'vue'

const props = defineProps({
  item: { type: Object as PropType<ShipType>, required: true },
  chartEvent: { type: String as PropType<string>, required: true },
  tableHeight: { type: Number, default: 40 }
})

const hideNoLine = ref(false)

const fairleads = computed<FairleadType[]>(() => {
  return props.item ? props.item.fairlead_set : []
})

const winches = computed<WinchType[]>(() => {
  return props.item ? props.item.winch_set : []
})

const pedestals = computed<PedestalType[]>(() => {
  return props.item ? props.item.pedestal_set : []
})

const showedFairleads = computed<FairleadType[]>(() => {
  let filteredFairleads: FairleadType[] = []
  if (hideNoLine.value) {
    fairleads.value.forEach((item) => {
      if (item.line_type) {
        filteredFairleads.push(item)
      }
    })
  } else {
    filteredFairleads = fairleads.value
  }
  return filteredFairleads
})

function getItemNumber(item: FairleadType): number {
  return fairleads.value.findIndex((fairlead) => fairlead === item)
}
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-table
      class="overflow-auto fairlead-table"
      density="compact"
      fixed-header
      :height="tableHeight + 'vh'"
      hover
    >
      <thead>
        <tr>
          <th class="pa-0 border-top-grey" style="width: 5%"></th>
          <th
            class="border-bottom-grey border-right-grey border-top-grey pl-0"
            colspan="4"
            style="width: 45%"
          >
            <v-checkbox
              v-model="hideNoLine"
              color="primary"
              hide-details
              label="Hide fairleads without lines"
            ></v-checkbox>
          </th>
          <th
            class="text-center border-bottom-grey border-right-grey border-top-grey"
            style="width: 50%"
            colspan="4"
          >
            Fairlead properties
          </th>
        </tr>
        <tr>
          <th class="pr-0">#</th>
          <th class="text-center">Fairlead</th>
          <th class="text-center">Pedestal</th>
          <th class="text-center">Winch</th>
          <th class="text-center pa-0">Line type</th>
          <th class="text-center pr-0 border-left-grey">X [m]</th>
          <th class="text-center pr-0">Y [m]</th>
          <th class="text-center border-right-grey">Z [m CD]</th>
          <th class="text-center border-right-grey pl-1 pr-1">Minimal (°)</th>
        </tr>
      </thead>
      <tbody v-if="showedFairleads.length" class="text-center fill-height pb-3">
        <template v-for="(item, index) in showedFairleads">
          <FairleadTableRow
            :chart-event="chartEvent"
            :index="index"
            :item="item"
            :item-number="getItemNumber(item)"
            :winches="winches"
            :pedestals="pedestals"
          />
        </template>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8" style="text-align: center">No data available</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped>
td {
  text-align: center;
}

th {
  text-align: center;
}

.fairlead-table {
  &:deep(.v-label) {
    font-size: 14px;
  }
  &:deep(.v-checkbox-btn) {
    min-height: 40px;
  }
}
</style>
