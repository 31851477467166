import {
  EventBusInterceptor,
  UseService,
  ApiServiceHandler
} from '@rhdhv/js-api-authentication-management'

import API from '@/api/apiUrls'
import { useEventsBus } from '@rhdhv/vue-component-library'

export const { emit } = useEventsBus()

const interceptor = new EventBusInterceptor(emit)

const baseURL = import.meta.env.VITE_APP_API_BASE_URL
const ShipApiServiceHandler = new ApiServiceHandler(
  baseURL,
  API.TOKEN.ACCESS,
  API.TOKEN.REFRESH,
  interceptor
)

export const useApi = () => {
  const api = new UseService(ShipApiServiceHandler)
  return { api }
}
