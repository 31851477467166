<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import AppHeader from '@/components/app/Header.vue'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { RHDHVSnackbar } from '@rhdhv/vue-component-library'

import useEventsBus from '@/stores/eventBus'
import { useDjangoAuthStore } from '@/stores/authDjango'

const { user, loading, rhdhvLoading } = storeToRefs(useDjangoAuthStore())

const { bus } = useEventsBus()
const snackbarStore = useSnackbarStore()

const showHeader = computed<boolean>(() => {
  return !!user.value && !(loading.value || rhdhvLoading.value)
})

watch(
  () => bus.value.get('error'),
  async (val) => {
    const errObj = await val[0]
    if (typeof errObj === 'string') {
      snackbarStore.setMessage(errObj, 'error', 5000)
      return
    }
    let errArr: string[] = []
    for (const [key, value] of Object.entries(errObj)) {
      errArr.push(`${key}: ${value}`)
    }
    const text = errArr.join('; ')
    snackbarStore.setMessage(text, 'error', 5000)
  }
)
</script>

<template>
  <v-app>
    <AppHeader v-if="showHeader"></AppHeader>
    <v-main>
      <router-view />
      <RHDHVSnackbar
        style="z-index: 3000"
        :excluded-http-methods="['GET', 'OPTIONS']"
      ></RHDHVSnackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import '/src/scss/settings.scss';
</style>
