<script lang="ts" setup>
import { PropType } from 'vue'
import type { ShipType } from '@/stores/type/ship.type'

import { useShipStore } from '@/stores/ship'

import SpecificInfoTableRow from '@/components/detail/SpecificInfoTableRow.vue'

const { options: shipOptions } = storeToRefs(useShipStore())

const props = defineProps({
  ship: { type: Object as PropType<ShipType>, required: true },
  tableHeight: { type: Number, default: 40 }
})

const mooringSideText = computed<string>(() => {
  switch (props.ship?.mooring_side) {
    case 'STARBOARD':
      return 'Starboard'
    case 'PORT_SIDE':
      return 'Port side'
    default:
      return '---'
  }
})

const getDisplayName = (param: keyof ShipType): string => {
  const choices = shipOptions.value[param]?.choices
  if (!choices) {
    return '---'
  }
  const choice = choices.find((c) => c.value === props.ship[param])
  return choice ? choice.display_name : '---'
}

const getUnit = (param: string): string => {
  const label = shipOptions.value[param]?.label
  let unit = '-'
  if (label.includes('[')) {
    unit = label.split('[')[1].split(']')[0]
  }
  return unit
}

const getVerboseName = (param: string): { text: string; unit: string } => {
  const label = shipOptions.value[param]?.label
  const text = label ? label.split('[')[0] : ''
  let unit = getUnit(param)
  return { text, unit }
}

const tableData = [
  {
    text: 'Ship size / payload',
    unit: 'varies',
    value: props.ship.ship_class
  },
  {
    ...getVerboseName('mooring_side'),
    value: getDisplayName('mooring_side')
  },
  {
    ...getVerboseName('ballasted_draught'),
    value: props.ship.ballasted_draught
  },
  { text: 'Depth (above keel)' },
  {
    text: 'Main deck',
    unit: getUnit('depth_main_deck_to_keel'),
    value: props.ship.depth_main_deck_to_keel,
    type: 'indent'
  },
  {
    text: 'Forecastle deck',
    unit: getUnit('depth_forecastle_deck_to_keel'),
    value: props.ship.depth_forecastle_deck_to_keel,
    type: 'indent'
  },
  {
    text: 'Poop deck',
    unit: getUnit('depth_poop_deck_to_keel'),
    value: props.ship.depth_poop_deck_to_keel,
    type: 'indent'
  },
  { text: 'Parallel body length' },
  {
    text: 'Forward of midship',
    unit: getUnit('forward_parallel_body_length'),
    value: props.ship.forward_parallel_body_length,
    type: 'indent'
  },
  {
    text: 'Aftward of midship',
    unit: getUnit('aftward_parallel_body_length'),
    value: props.ship.aftward_parallel_body_length,
    type: 'indent'
  },
  { ...getVerboseName('length_between_perps'), value: props.ship.length_between_perps },
  { ...getVerboseName('allowed_hull_pressure'), value: props.ship.allowed_hull_pressure },
  null,
  { ...getVerboseName('main_engine_power'), value: props.ship.main_engine_power },
  { ...getVerboseName('main_engine_number'), value: props.ship.main_engine_number },
  { ...getVerboseName('main_engine_diameter'), value: props.ship.main_engine_diameter },
  {
    ...getVerboseName('main_engine_distance_between'),
    value: props.ship.main_engine_distance_between
  },
  {
    ...getVerboseName('main_engine_distance_tip_keel'),
    value: props.ship.main_engine_distance_tip_keel
  },
  {
    ...getVerboseName('main_engine_distance_ship_stern'),
    value: props.ship.main_engine_distance_ship_stern
  },
  { ...getVerboseName('rudder'), value: getDisplayName('rudder') },
  {
    ...getVerboseName('main_engine_type'),
    value: getDisplayName('main_engine_type')
  },
  null,
  {
    ...getVerboseName('transverse_thruster_power'),
    value: props.ship.transverse_thruster_power
  },
  {
    ...getVerboseName('transverse_thruster_number'),
    value: props.ship.transverse_thruster_number
  },
  {
    ...getVerboseName('transverse_thruster_diameter'),
    value: props.ship.transverse_thruster_diameter
  },
  {
    ...getVerboseName('transverse_thruster_distance_between'),
    value: props.ship.transverse_thruster_distance_between
  },
  {
    ...getVerboseName('transverse_thruster_distance_tip_keel'),
    value: props.ship.transverse_thruster_distance_tip_keel
  },
  {
    ...getVerboseName('transverse_thruster_duct_length'),
    value: props.ship.transverse_thruster_duct_length
  },
  {
    ...getVerboseName('transverse_thruster_type'),
    value: getDisplayName('transverse_thruster_type')
  }
]
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-table
      :height="tableHeight + 'vh'"
      class="overflow-auto"
      density="compact"
      fixed-header
      hover
    >
      <thead>
        <tr>
          <th style="width: 2%" class="pl-0"></th>
          <th class="text-center border-top-grey pl-0" style="width: 58%">Relevant Parameter</th>
          <th class="text-center border-left-grey border-top-grey" style="width: 20%">Unit</th>
          <th class="text-center border-left-grey border-top-grey" style="width: 20%">Value</th>
        </tr>
      </thead>
      <tbody v-if="ship" class="text-center fill-height pb-3">
        <template v-for="data in tableData">
          <SpecificInfoTableRow :data="data" />
        </template>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8" style="text-align: center">No data available</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped>
th {
  text-align: center;
}
</style>
