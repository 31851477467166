<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'
import MultipleSelectField from '@/components/common/MultipleSelectField.vue'
import { debounce } from 'lodash'
import { useApplicationStore } from '@/stores/application'
import type { FilterOptions, FilterSelect, ObjectElement } from '@/stores/type/storeTypes'
import RadioField from '@/components/common/RadioField.vue'
import { useShipTypeStore } from '@/stores/shipType'
import RadioWithColumnField from '@/components/common/RadioWithColumnField.vue'

const DEFAULT_OPTIONS = { text: 'All', value: null }

const shipStore = useShipStore()
const { filter: stateFilter, items: ships } = storeToRefs(shipStore)

const { items: applications } = storeToRefs(useApplicationStore())
const { items: shipTypes } = storeToRefs(useShipTypeStore())

const filterIcon = computed<string>(() => {
  return JSON.stringify(stateFilter.value) === '{}' ? 'mdi-filter' : 'mdi-filter-check'
})
const selectedType = ref<ObjectElement<string | null>>(DEFAULT_OPTIONS)
const selectedApproved = ref<ObjectElement<string | null>>(DEFAULT_OPTIONS)
const selectedApplication = ref<string[]>([])
const selectedMooringSide = ref<ObjectElement<string | null>>(DEFAULT_OPTIONS)
const selectedArmour = ref<ObjectElement<string | null>>(DEFAULT_OPTIONS)
const selectedShipDesign = ref<ObjectElement<string | null>>(DEFAULT_OPTIONS)
const filterName = ref<Object>({})
const openMenu = ref<boolean>(false)

const typeSelect = computed<FilterSelect>(() => {
  return { [typeOptions.value.field]: selectedType.value.value }
})
const mooringSideSelect = computed<FilterSelect>(() => {
  return { [mooringSideOptions.value.field]: selectedMooringSide.value.value }
})
const approvedSelect = computed<FilterSelect>(() => {
  return { [approvedOptions.value.field]: selectedApproved.value.value }
})
const hasDesignSelect = computed<FilterSelect>(() => {
  return { [hasDesignOptions.value.field]: selectedShipDesign.value.value }
})
const hasArmourSelect = computed<FilterSelect>(() => {
  return { [hasArmourOptions.value.field]: selectedArmour.value.value }
})
const applicationSelect = computed<FilterSelect>(() => {
  return { [applicationOptions.value.field]: selectedApplication.value.join(',') }
})

const typeOptions = computed<FilterOptions<string>>(() => {
  return {
    field: 'type',
    options: shipTypes.value.map((shipType) => {
      return { text: shipType.name, value: shipType.name }
    })
  }
})
const mooringSideOptions = ref<FilterOptions<string>>({
  field: 'mooring_side',
  options: [
    { text: 'Port side', value: 'PORT_SIDE' },
    { text: 'Starboard', value: 'STARBOARD' }
  ]
})
const hasArmourOptions = ref<FilterOptions<string>>({
  field: 'has_armour_param',
  options: [
    { text: 'Has ARMOUR parameters', value: 'true' },
    { text: 'No ARMOUR parameters', value: 'false' }
  ]
})
const approvedOptions = ref<FilterOptions<string>>({
  field: 'approved',
  options: [
    { text: 'Approved', value: 'true' },
    { text: 'Not approved', value: 'false' }
  ]
})
const hasDesignOptions = ref<FilterOptions<string>>({
  field: 'has_ship_design',
  options: [
    { text: 'Plan view available', value: 'true' },
    { text: 'No plan view available', value: 'false' }
  ]
})
const applicationOptions = computed<FilterOptions<string>>(() => {
  return {
    field: 'application',
    options: applications.value.map((app) => {
      return { text: app.name, value: app.search_name }
    })
  }
})

const selectedApprovedValue = computed<string | null>(() => {
  return selectedApproved.value.value
})

watch(selectedApprovedValue, async () => {
  if (selectedApproved.value.value === null) {
    selectedApplication.value = []
  }
})

const search = debounce(async (text) => {
  filterName.value = text ? { name: text } : {}
  await filter()
}, 600)

async function filter(): Promise<void> {
  stateFilter.value = {
    ...typeSelect.value,
    ...applicationSelect.value,
    ...approvedSelect.value,
    ...mooringSideSelect.value,
    ...hasDesignSelect.value,
    ...hasArmourSelect.value,
    ...filterName.value
  }
  openMenu.value = false
  shipStore.resetState()
  await shipStore.fetchPaginationItems()
  if (ships.value.length) {
    shipStore.setCurrentItem(ships.value[0])
  }
}
</script>

<template>
  <v-row justify="center" align="stretch">
    <v-col cols="8" class="pr-1">
      <v-text-field
        :loading="false"
        density="compact"
        hide-details
        label="Search name"
        variant="solo-filled"
        @update:model-value="search"
      ></v-text-field>
    </v-col>
    <v-col cols="4" class="pl-1">
      <v-btn
        :prepend-icon="filterIcon"
        class="fill-height d-flex justify-center align-center"
        color="primary"
        variant="flat"
        density="compact"
      >
        Filter
        <v-menu :close-on-content-click="false" v-model="openMenu" activator="parent">
          <v-card width="1000">
            <v-row no-gutters class="pa-2">
              <v-col cols="8">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">Type</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <RadioWithColumnField
                    v-model="selectedType"
                    :options="typeOptions.options"
                  ></RadioWithColumnField>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">Status knowledge group review</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <RadioField
                    v-model="selectedApproved"
                    :options="approvedOptions.options"
                  ></RadioField>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">Applications</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <MultipleSelectField
                    v-model="selectedApplication"
                    :disabled-all="selectedApproved.value === null"
                    :options="applicationOptions.options"
                  ></MultipleSelectField>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">Plan view of ship</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <RadioField
                    v-model="selectedShipDesign"
                    :options="hasDesignOptions.options"
                  ></RadioField>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">Mooring side</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <RadioField
                    v-model="selectedMooringSide"
                    :options="mooringSideOptions.options"
                  ></RadioField>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="pa-0">
                  <v-card-title class="pb-0">ARMOUR parameters</v-card-title>
                  <v-divider class="mx-2"></v-divider>
                  <RadioField
                    v-model="selectedArmour"
                    :options="hasArmourOptions.options"
                  ></RadioField>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" variant="flat" @click="filter()"> Apply </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-btn>
    </v-col>
  </v-row>
</template>
