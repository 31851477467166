<script lang="ts" setup>
import type { PropType } from 'vue'
import { useDocumentStore } from '@/stores/document'
import { storeToRefs } from 'pinia'
import type { DocumentType } from '@/stores/type/document.type'

const props = defineProps({
  document: { type: Object as PropType<DocumentType>, required: true }
})

const documentStore = useDocumentStore()
const { downloadLoading } = storeToRefs(documentStore)

const isDownloading = ref<boolean>(false)

watch(downloadLoading, () => {
  if (!downloadLoading.value) {
    isDownloading.value = false
  }
})

function downloadDocument(documentId: number) {
  documentStore.downloadDocument(documentId)
  isDownloading.value = true
}
</script>

<template>
  <tr style="height: 40px">
    <td class="border-right-grey" style="text-align: center">
      <v-icon color="success">mdi-check-bold</v-icon>
    </td>
    <td>{{ document.file_name }}</td>
    <td style="text-align: right">
      <v-btn
        :loading="downloadLoading && isDownloading"
        density="compact"
        icon="mdi-download"
        variant="text"
        color="primary"
        @click="downloadDocument(document.id)"
      >
      </v-btn>
    </td>
  </tr>
</template>
