<script setup lang="ts">

import {storeToRefs} from "pinia";
import {useShipStore} from "@/stores/ship";
import {useDocumentStore} from "@/stores/document";

const documentStore = useDocumentStore()
const {previewDocumentDialog} = storeToRefs(useShipStore())

const documentFiles = ref<File[]>([])

function add() {
  documentStore.addFiles(documentFiles.value)
  previewDocumentDialog.value = false
  documentFiles.value = []
}

const emit = defineEmits(['upload', 'close'])
</script>
<template>
  <v-dialog
      v-model="previewDocumentDialog"
      max-width="800px"
      persistent
  >
    <v-card>
  <v-card-title>
        <span class="headline"> Add document </span>
        <v-btn
            center
            color="primary"
            icon="mdi-close"
            small style="float: right"
            variant="text"
            @click="emit('close')"
        />
      </v-card-title>
      <v-row class="mx-3 pb-3" no-gutters>
        <v-col class="pr-3" cols="10">
          <v-file-input
              v-model="documentFiles"
              chips
              color="primary"
              density="compact"
              hide-details
              label="Upload document"
              multiple
              truncate-length="20"
          ></v-file-input>
        </v-col>
        <v-col cols="2" style="display: flex; align-items: center">
          <v-btn
              :disabled="!documentFiles.length"
              append-icon="mdi-plus"
              block
              center
              color="primary"
              small
              variant="flat"
              @click="add"
          > Add
          </v-btn>
        </v-col>
      </v-row>
      </v-card>
  </v-dialog>
</template>