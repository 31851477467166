<script lang="ts" setup>
import type { PropType } from 'vue'
import type { ObjectElement } from '@/stores/type/storeTypes'

const all = ref<boolean>(true)
const selection = ref<Object[]>([])
const props = defineProps({
  modelValue: { type: Array<string>, required: true },
  options: { type: Object as PropType<ObjectElement<any>[]>, required: true },
  disabledAll: { type: Boolean, default: false }
})

const modelValueLength = computed<number>(() => {
  return props.modelValue.length
})

watch(modelValueLength, () => {
  if (!modelValueLength.value) {
    all.value = true
  }
})

watch(all, (newValue: boolean) => {
  if (newValue) {
    selection.value = []
  } else if (selection.value.length === 0) {
    all.value = true
  }
  emit('update:modelValue', selection.value)
})

watch(selection, () => {
  all.value = !selection.value.length
  emit('update:modelValue', selection.value)
})

const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  if (!props.modelValue?.length) {
    all.value = true
  } else {
    selection.value = props.modelValue
    all.value = false
  }
})
</script>

<template>
  <v-list>
    <v-list-item class="py-0">
      <v-checkbox
        color="primary"
        v-model="all"
        hide-details
        :disabled="disabledAll"
        label="All"
      ></v-checkbox>
    </v-list-item>
    <template v-for="option in options">
      <v-list-item class="py-0">
        <v-checkbox
          color="primary"
          v-model="selection"
          :label="option.text"
          :disabled="disabledAll"
          :value="option.value"
          hide-details
        ></v-checkbox>
      </v-list-item>
    </template>
  </v-list>
</template>

<style scoped>
.v-checkbox {
  min-width: 12vh;
  &:deep(.v-selection-control) {
    min-height: 40px;
  }
}
.v-list {
  &:deep(.v-list-item) {
    min-height: 40px;
    &:deep(.v-list-item__content) {
      height: 40px;
    }
  }
}
</style>
