<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'

const shipStore = useShipStore()
</script>

<template>
  <div style="height: 100%; float: right">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
          append-icon="mdi-import"
          style="min-height: 40px"
          class="fill-height"
          color="primary"
          v-bind="props"
          variant="flat"
          density="compact"
        >
          Create/Import
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="shipStore.toggleCreateShipDialog()">
          <v-list-item-title>
            <v-tooltip text="Only information shown in the main list (name, type, capacity, LxBxD)">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-information-slab-circle-outline</v-icon>
              </template>
            </v-tooltip>
            Create base ship
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="shipStore.toggleCreateFullShipDialog()">
          <v-list-item-title>
            <v-tooltip text="Import new detailed specs including information using the template">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-information-slab-circle-outline</v-icon>
              </template>
            </v-tooltip>
            Import by template
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
