import { defineStore } from 'pinia'
import type { SnackBar } from '@/stores/type/storeTypes'

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackBar => ({
    active: false,
    message: '',
    color: 'primary',
    closeColor: 'white',
    timeout: 5000
  }),
  actions: {
    setMessage(
      message: string,
      color: string = 'secondary',
      timeout: number = 5000,
      closeColor: string = 'white'
    ) {
      this.message = message
      this.color = color
      this.timeout = timeout
      this.active = true
      this.closeColor = closeColor
    }
  }
})
