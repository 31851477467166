<template>
  <v-layout class="center" style="height: 100%; width: 100%">
    <div class="text-center">
      <div class="code-number">404</div>
      <div class="message">It seems like you are trying to access a page which does not exists</div>
      <div class="mt-6">
        <v-btn
          :to="{ name: 'project-index' }"
          append-icon="mdi-home"
          color="primary"
          rounded="pill"
        >
          Go home
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<style scoped>
.code-number {
  font-family: Bahnschrift, serif;
  font-size: 148px;
  font-weight: lighter;
  height: 180px;
}
.message {
  font-family: Bahnschrift, serif;
  font-size: 24px;
}
</style>
