<script lang="ts" setup>
import type {PropType} from "vue";
import {useDocumentStore} from "@/stores/document";
import {storeToRefs} from "pinia";
import type {UploadDocumentType} from "@/stores/type/document.type";

const props = defineProps({
  document: {type: Object as PropType<UploadDocumentType>, required: true},
})

const documentStore = useDocumentStore()
const {uploadLoading} = storeToRefs(documentStore)

const emit = defineEmits(['remove'])
</script>

<template>
  <tr style="height: 40px;">
        <td class="border-right-grey" style="text-align: center">
          <v-progress-circular v-if="document.loading && !document.response" :size="20" indeterminate></v-progress-circular>
          <v-icon color="error" v-else-if="!document.loading && document.failed">mdi-close-thick</v-icon>
        </td>
        <td>{{ document.file.name }}</td>
        <td style="text-align: right">
          <v-btn v-if="!uploadLoading && !document.sent || document.failed" color="primary" density="compact" icon="mdi-close-circle-outline" variant="text"
                 @click="emit('remove')"></v-btn>
        </td>
      </tr>
</template>