<template>
  <tr>
    <th class="border-bottom-grey pl-4" style="width: 20%">Name</th>
    <th class="border-bottom-grey pl-4" style="width: 12%">Type</th>
    <th class="border-bottom-grey text-center" style="width: 12%">Mooring side</th>
    <th class="border-bottom-grey text-center" style="width: 12%">Capacity <br/> [varies]</th>
    <th class="border-bottom-grey text-center" style="width: 13%">Length overall <br/> [m]</th>
    <th class="border-bottom-grey text-center" style="width: 13%">Breadth/beam <br/> [m]</th>
    <th class="border-bottom-grey text-center" style="width: 13%">Laden draught <br/> [m]</th>
    <th class="border-bottom-grey text-center" style="width: 13%">Laden displacement <br/> [m<sup>3</sup>]</th>
  </tr>
</template>