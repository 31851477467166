import { defineStore } from 'pinia'
import type { ApprovalStateType, ApprovalType } from '@/stores/type/approval.type'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import { useApi } from '@/api/useApi'

const { api } = useApi()

const baseUrl = API.APPROVAL.BASE
const baseStore = new AppBaseStore<ApprovalType>(baseUrl, api)

export const useApprovalStore = defineStore('approval', {
  state: (): ApprovalStateType => ({
    ...baseStore.appState
  }),
  actions: {
    ...baseStore.appActions
  }
})
