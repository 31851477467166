export function getUrlQueryParams(params: {}): string {
    if (Object.keys(params).length !== 0) {
        let query = "?"
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                query += key + "=" + value.toString() + "&"
            }
        })
        if (query.includes("&")) {
            query = query.slice(0, query.length - 1)
        }
        return query
    } else {
        return ''
    }
}