<script lang="ts" setup>
import ShipTable from '@/components/ships/ShipTable.vue'
import CreateShipDialog from '@/components/dialog/CreateShipDialog.vue'
import ImportShipDialogHandler from '@/components/import/ImportShipDialogHandler.vue'
import type { PropType } from 'vue'
import DocumentDialog from '@/components/dialog/DocumentDialog.vue'
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import { useDocumentStore } from '@/stores/document'
import ShipActionMenu from '@/components/ships/ShipActionMenu.vue'

const props = defineProps({
  chartEvent: { type: String as PropType<string>, required: true }
})

const documentStore = useDocumentStore()
const shipStore = useShipStore()
const { uploadItems } = storeToRefs(documentStore)
const { documentDialog, currentItem } = storeToRefs(useShipStore())

async function upload(): Promise<void> {
  if (currentItem.value?.id) {
    await documentStore.uploadDocumentFiles(currentItem.value.id)
  }
}

function close(): void {
  if (currentItem.value) {
    shipStore.addDocuments(currentItem.value)
  }
  uploadItems.value = []
  documentDialog.value = false
}
</script>

<template>
  <div style="position: relative" class="fill-height">
    <ShipActionMenu />
    <ShipTable :chart-event="chartEvent" class="mt-2" />
    <v-tooltip text="Right click on the ship to see the menu">
      <template v-slot:activator="{ props }">
        <v-icon
          icon="mdi-information-outline"
          v-bind="props"
          class="mr-2 mb-6"
          style="position: absolute; right: 0; bottom: 0"
        >
        </v-icon>
      </template>
    </v-tooltip>
  </div>
  <CreateShipDialog />
  <ImportShipDialogHandler />
  <DocumentDialog @upload="upload" @close="close" />
</template>
