<script lang="ts" setup>
import {useShipStore} from "@/stores/ship";
import {storeToRefs} from "pinia";

const shipStore = useShipStore()
const {items: ships, createFullShipDialog, uploadLoading} = storeToRefs(shipStore)

const props = defineProps({
  modelValue: {type: Array<File>, required: true},
})

const emit = defineEmits(['update:modelValue', 'submit'])

const vModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  }
})
</script>

<template>
  <v-dialog
      v-model="createFullShipDialog"
      max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Import ship </span>
      </v-card-title>
        <v-row no-gutters class="px-3 pb-6">
          <v-col cols="10" class="pr-3">
            <v-file-input
                density="compact"
                color="primary"
                v-model="vModel"
                hide-details
                class="file-input"
                label="File input (using the template)"
                truncate-length="50"
            ></v-file-input>
          </v-col>
          <v-col cols="2" style="display: flex; align-items: center">
            <v-btn
                :loading="uploadLoading"
                append-icon="mdi-cloud-upload"
                center
                color="primary" small
                variant="flat"
                style="height: 100%"
                @click="emit('submit')"
            >Upload
            </v-btn>
          </v-col>
        </v-row>
    </v-card>
  </v-dialog>
</template>