import { defineStore } from 'pinia'
import API from '@/api/apiUrls'
import type { FairleadType } from '@/stores/type/ship.type'
import { useApi } from '@/api/useApi'
import AppBaseStore from '@/stores/baseStore'

const baseUrl = API.FAIRLEAD.BASE

const { api } = useApi()
const baseStore = new AppBaseStore<FairleadType>(baseUrl, api)

export const useFairleadStore = defineStore('fairlead', {
  state: () => ({
    ...baseStore.appState
  }),
  actions: {
    ...baseStore.appActions
  }
})
