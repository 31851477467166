import { createRouter, createWebHistory } from 'vue-router'
import ShipView from '@/views/ships/ShipView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import { useShipStore } from '@/stores/ship'
import { authRoutes } from '@rhdhv/vue-authentication-middleware'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundView
    },
    {
      path: '/',
      name: 'home',
      redirect: {
        name: 'auth-login'
      }
    },
    authRoutes,
    {
      path: '/SHIP',
      name: 'project-index',
      component: ShipView,
      beforeEnter: (to, from) => {
        const store = useShipStore()
        store.resetState()
        return true
      }
    }
  ]
})

router.beforeEach(async (to, from) => {
  const authStore = inject('useAuthStore')

  // TODO: This weirdness should not be necessary
  let userResponse = await authStore.reAuthenticate()
  if (userResponse) {
    authStore.$patch({
      user: userResponse.user
    })
  }

  if (to.name.startsWith('auth-')) {
    if (userResponse && userResponse.user) {
      return { name: 'project-index' }
    }
    return true
  }
  //check auth and apply login redirect
  if (!authStore.$state.user) {
    authStore.loginRedirect = to
    return { name: 'auth-login' }
  }
  return true
})

export default router
