<script setup lang="ts">
import { useDjangoAuthStore } from '@/stores/authDjango'
import router from '@/router'

const authStore = useDjangoAuthStore()
const { user } = storeToRefs(authStore)
async function logout() {
  await authStore.logout()
  await router.push({ name: 'auth-login' })
}
</script>

<template>
  <v-menu class="center" :offset-x="true">
    <template v-slot:activator="{ props }">
      <v-btn icon color="primary" @mousedown.stop v-bind="props">
        <v-icon @mousedown.stop>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list v-if="user" density="comfortable">
      <v-list-item>
        <v-list-item-title>
          <v-row>
            <v-col cols="2">
              <v-icon> mdi-account </v-icon>
            </v-col>
            <v-col cols="10">
              {{ user.email }}
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="logout" min-width="180px">
        <v-list-item-title>
          <v-row>
            <v-col cols="2">
              <v-icon> mdi-logout-variant </v-icon>
            </v-col>
            <v-col cols="10"> Log out </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}
</style>
