<script lang="ts" setup>
const props = defineProps({
  item: { type: Object, required: true },
  parameter: { type: String, required: true },
  disabled: { type: Boolean, required: false, default: false },
  label: { type: String, required: false, default: '' }
})

const value = computed({
  get: () => props.item[props.parameter],
  set: (value) => {
    emit('set', value)
    emit('update')
  }
})

const emit = defineEmits<{
  (e: 'set', value: boolean): void
  (e: 'update'): void
}>()
</script>

<template>
  <v-checkbox
    v-model="value"
    :disabled="disabled"
    class="my-0"
    hide-details
    :label="label"
    color="primary"
  ></v-checkbox>
</template>

<style scoped>
.v-checkbox {
  &:deep(.v-selection-control) {
    min-height: 40px;
  }
}
</style>
