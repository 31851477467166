<script lang="ts" setup>
import type { PropType } from 'vue'
import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'
import DetailTab from '@/components/detail/DetailTab.vue'
import ApprovalMenu from '@/components/ships/ApprovalMenu.vue'
import type { ShipType } from '@/stores/type/ship.type'

const shipStore = useShipStore()
const { currentItem: currentShip } = storeToRefs(shipStore)

const props = defineProps({
  item: { type: Object as PropType<ShipType>, required: true },
  previewState: { type: Boolean, default: false },
  chartEvent: { type: String as PropType<string>, required: true },
  tableHeight: { type: Number, default: 70 }
})

const open = ref(false)

const mooringSideText = computed<string>(() => {
  switch (props.item?.mooring_side) {
    case 'STARBOARD':
      return 'Starboard'
    case 'PORT_SIDE':
      return 'Port side'
    default:
      return '---'
  }
})

const tableHeight = computed<number>(() => {
  return props.previewState ? props.tableHeight - 15 : 40
})

const isSelectedShip = computed<string>(() => {
  return currentShip.value?.id === props.item?.id ? 'marked-left-border pl-2' : 'pl-3'
})

function toggleOpen(item: ShipType): void {
  if (props.previewState) {
    return
  }
  shipStore.setCurrentItem(item)
  if (props.item?.has_ship_design || props.item?.has_armour_param) {
    open.value = !open.value
  }
}

function toggleMenu(event: MouseEvent, item: ShipType): void {
  if (props.previewState) {
    return
  }
  shipStore.toggleMenu({ x: event.clientX, y: event.clientY }, item)
}

const headers = ref([
  { title: 'Capacity', align: 'end', key: 'capacity' },
  { title: 'Overall', align: 'end', key: 'length_overall' },
  { title: 'Breadth', align: 'end', key: 'breadth' },
  { title: 'Laden draught', align: 'end', key: 'laden_draught' },
  { title: 'Displacement', align: 'end', key: 'laden_displacement' }
])
</script>

<template>
  <tr
    v-if="item"
    v-ripple="!previewState"
    :class="[!previewState ? 'clickable hover-event' : '']"
    @click="toggleOpen(item)"
    @contextmenu.prevent="toggleMenu($event, item)"
  >
    <td v-if="!previewState" :class="[isSelectedShip, 'pr-0']">
      <v-tooltip text="This ship has design, Click on this row for more detailed information.">
        <template v-slot:activator="{ props }">
          <v-icon v-if="item.has_ship_design || item.has_armour_param" v-bind="props">
            {{ open ? `mdi-arrow-up-drop-circle` : `mdi-arrow-down-drop-circle` }}
          </v-icon>
        </template>
      </v-tooltip>
    </td>
    <v-tooltip :text="item.name">
      <template v-slot:activator="{ props }">
        <td class="pl-1, pr-0 name-cell" v-bind="props">{{ item.name }}</td>
      </template>
    </v-tooltip>
    <td class="pl-1, pr-0">{{ item.fulltype }}</td>
    <td class="text-center">{{ mooringSideText }}</td>
    <td v-for="header in headers" class="text-center">{{ item[header.key] }}</td>
    <td v-if="!previewState" class="text-center">
      <ApprovalMenu
        :ship="item"
        @click="shipStore.setCurrentItem(item)"
        @update="shipStore.update(item)"
      ></ApprovalMenu>
    </td>
  </tr>
  <tr v-if="item && (item.has_ship_design || item.has_armour_param)">
    <td
      v-if="open || previewState"
      class="pl-0 pr-0"
      colspan="999"
      @click.stop="shipStore.setCurrentItem(item)"
    >
      <DetailTab :chart-event="chartEvent" :item="item" :table-height="tableHeight"></DetailTab>
      <v-divider v-if="!previewState" :thickness="2"></v-divider>
    </td>
  </tr>
</template>

<style scoped>
.hover-event:hover {
  background-color: #bfbfbf;
}

.name-cell {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
