import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbarStore'
import type { PaginationServer } from '@/stores/type/storeTypes'
import { getUrlQueryParams } from '@/utils/getUrlQueryParams'
import { useDocumentStore } from '@/stores/document'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import type {
  BaseShipType,
  CustomShipState,
  ShipStateType,
  ShipType
} from '@/stores/type/ship.type'
import { useDjangoAuthStore } from '@/stores/authDjango'
import { useApi } from '@/api/useApi'
import { useEventsBus } from '@rhdhv/vue-component-library'

const baseUrl = API.SHIP.BASE
const paginateUrl = API.SHIP.PAGINATE

const { api } = useApi()
const baseStore = new AppBaseStore<ShipType>(baseUrl, api)

const defaultCustomState: CustomShipState = {
  createShipDialog: false,
  downloadLoading: false,
  createFullShipDialog: false,
  previewFullShipDialog: false,
  documentDialog: false,
  previewDocumentDialog: false,
  uploadLoading: false,
  importSuccess: false,
  importLoading: false,
  importItem: null,
  currentItem: null,
  filter: {},
  fetchLoading: false,
  menu: false,
  menuCoordinates: { x: 0, y: 0 },
  paginationServer: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  fields: [
    { field: 'ship_class', type: 'text', label: 'Ship class' },
    { field: 'capacity', type: 'number', label: 'Payload [m³]' },
    { field: 'depth_main_deck_to_keel', type: 'number' },
    { field: 'length_overall', type: 'number' },
    { field: 'breadth', type: 'number' },
    { field: 'laden_draught', type: 'number' },
    { field: 'ballasted_draught', type: 'number' },
    {
      field: 'forward_parallel_body_length',
      type: 'number',
      label: 'Parallel body length fore [m] (optional)'
    },
    {
      field: 'aftward_parallel_body_length',
      type: 'number',
      label: 'Parallel body length aft [m] (optional)'
    }
  ],
  armourFields: [
    { field: 'main_engine_power', type: 'number' },
    { field: 'main_engine_diameter', type: 'number' },
    { field: 'main_engine_distance_between', type: 'number' },
    { field: 'main_engine_distance_tip_keel', type: 'number' },
    { field: 'main_engine_distance_ship_stern', type: 'number' },
    { field: 'transverse_thruster_power', type: 'number' },
    { field: 'transverse_thruster_diameter', type: 'number' },
    { field: 'transverse_thruster_distance_between', type: 'number' },
    { field: 'transverse_thruster_distance_tip_keel', type: 'number' },
    { field: 'transverse_thruster_duct_length', type: 'number' },
    { field: 'rudder', type: 'select' },
    { field: 'main_engine_type', type: 'select' },
    { field: 'transverse_thruster_type', type: 'select' },
    { field: 'main_engine_number', type: 'number' },
    { field: 'transverse_thruster_number', type: 'number' }
  ]
}

export const useShipStore = defineStore('ship', {
  state: (): ShipStateType => ({
    ...baseStore.appState,
    ...defaultCustomState
  }),
  actions: {
    ...baseStore.appActions,
    resetState(): void {
      this.currentItem = null
      this.items = []
      this.fetchLoading = defaultCustomState.fetchLoading
      this.paginationServer = defaultCustomState.paginationServer
    },
    async fetchPaginationItems(filter = {}, itemsPerPage = 10): Promise<ShipType[] | boolean> {
      this.fetchLoading = true
      const filterPagination = {
        ...filter,
        ...this.filter,
        page_size: itemsPerPage
      }
      let url = paginateUrl + getUrlQueryParams(filterPagination)
      if (this.paginationServer.next) {
        url = this.paginationServer.next
      } else {
        if (this.paginationServer.previous) {
          this.fetchLoading = false
          return []
        }
      }
      try {
        const response = await api.service(url).get()
        const data = response as PaginationServer<ShipType>
        this.items.push(...data.results)
        this.paginationServer = data
      } catch (e) {
        console.error(e)
        this.fetchLoading = false
        return false
      }
      this.fetchLoading = false
      return this.items
    },
    toggleCreateShipDialog(value: boolean | null = null): void {
      this.createShipDialog = value ?? !this.createShipDialog
    },
    toggleCreateFullShipDialog(value: boolean | null = null): void {
      this.createFullShipDialog = value ?? !this.createFullShipDialog
    },
    async uploadFile(formData: FormData): Promise<boolean> {
      this.uploadLoading = true
      try {
        const url = baseUrl + 'upload/'
        const response = await api.service(url).post(formData)

        const { emit } = useEventsBus()
        const generatedMessage = {
          status: 200,
          data: [],
          method: 'POST',
          statusText: 'Generated ship from uploaded file!'
        }
        emit('success', generatedMessage)

        this.importItem = response as ShipType
        this.uploadLoading = false
        return true
      } catch {
        this.uploadLoading = false
        return false
      }
    },
    async import(): Promise<ShipType | null> {
      this.importLoading = true
      try {
        const url = baseUrl + 'import/'
        const formData = new FormData()
        formData.append('ship', JSON.stringify(this.importItem))
        const response = await api.service(url).post(formData)
        this.importLoading = false
        this.importSuccess = true
        return response as ShipType
      } catch (e) {
        console.log(e)
        this.importLoading = false
        throw e
      }
    },
    toggleMenu(coordinates: { x: number; y: number } | null = null, item: ShipType | null = null) {
      if (coordinates) {
        this.menuCoordinates = coordinates
      }
      if (item) {
        this.currentItem = item
        this.menu = true
      } else {
        this.menu = !this.menu
      }
    },
    addDocuments(item: ShipType): void {
      const documentStore = useDocumentStore()
      const successItems = documentStore.uploadItems.filter((uploadItem) => uploadItem.response)
      successItems.forEach((successItem) => {
        if (successItem.response) {
          item.document_set.push(successItem.response)
        }
      })
    },
    async downloadTemplate(): Promise<void> {
      const url = baseUrl + 'download_template/'
      await this.download(url)
    },
    async exportExcel(id: number): Promise<void> {
      const url = baseUrl + id + '/export/'
      await this.download(url)
    },
    async exportAutoCAD(id: number): Promise<void> {
      const url = baseUrl + id + '/autocad_export/'
      await this.download(url)
    },
    async download(fileURL: string): Promise<void> {
      this.downloadLoading = true
      const { emit } = useEventsBus()
      try {
        await api.service(fileURL).getBlob()

        const downloadMessage = {
          status: 200,
          data: [],
          // using POST method instead of GET method to show the download snackbar
          method: 'POST',
          statusText: 'Downloaded!'
        }
        emit('success', downloadMessage)
      } catch (e) {
        console.log(e)
      }
      this.downloadLoading = false
    }
  },
  getters: {
    getEmptyItem(): BaseShipType {
      return {
        name: '',
        type: null,
        ship_class: '',
        capacity: 0,
        length_overall: 0,
        breadth: 0,
        laden_draught: 0,
        ballasted_draught: 0,
        depth_main_deck_to_keel: 0,
        forward_parallel_body_length: 0,
        aftward_parallel_body_length: 0,
        has_ship_design: false,
        has_armour_param: false,
        id: 0
      }
    },
    deletePermission: (state: ShipStateType) => {
      const isNotApproved = state.currentItem?.approval_set.every((approval) => !approval.approved)
      const djangoAuthStore = useDjangoAuthStore()
      return (
        djangoAuthStore.user?.is_superuser ||
        (state.currentItem?.user === djangoAuthStore.user?.id && isNotApproved)
      )
    }
  }
})
