<script lang="ts" setup>
import { PropType } from 'vue'
import { ObjectElement } from '@/stores/type/storeTypes'

const props = defineProps({
  modelValue: { type: Object as PropType<ObjectElement<any>>, required: true },
  options: { type: Object as PropType<ObjectElement<any>[]>, required: true }
})

const value = ref<ObjectElement<any>>({ text: '', value: null })
watch(value, (newValue: ObjectElement<any>) => {
  emit('update:modelValue', newValue)
})

const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <v-radio-group v-model="value" inline hide-details>
    <v-list>
      <v-list-item class="py-0">
        <v-radio color="primary" :value="{ text: 'All', value: null }" label="All"></v-radio>
      </v-list-item>
      <template v-for="option in options">
        <v-list-item class="py-0">
          <v-radio color="primary" :label="option.text" :value="option"></v-radio
        ></v-list-item>
      </template>
    </v-list>
  </v-radio-group>
</template>

<style scoped>
.v-list {
  &:deep(.v-list-item) {
    min-height: 40px;
    &:deep(.v-list-item__content) {
      height: 40px;
    }
  }
}
</style>
