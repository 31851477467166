<script lang="ts" setup>
import { PropType } from 'vue'
import { ObjectElement } from '@/stores/type/storeTypes'

const props = defineProps({
  modelValue: { type: Object as PropType<ObjectElement<any>>, required: true },
  options: { type: Object as PropType<ObjectElement<any>[]>, required: true }
})

const DEFAULT_COLUMN = 3

const value = ref<ObjectElement<any>>({ text: '', value: null })
watch(value, (newValue: ObjectElement<any>) => {
  emit('update:modelValue', newValue)
})

const columns = computed(() => {
  const options = [{ text: 'All', value: null }, ...props.options]
  let columns: ObjectElement<any>[][] = []
  for (let i = 0; i < DEFAULT_COLUMN; i++) {
    columns.push([])
  }
  let i = 0
  while (options.length > 0) {
    columns[i % DEFAULT_COLUMN].push(...options.slice(0, 1))
    options.splice(0, 1)
    i++
  }
  return columns
})

const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <v-radio-group v-model="value" inline hide-details>
    <v-row no-gutters>
      <template v-for="column in columns">
        <v-col :cols="12 / DEFAULT_COLUMN">
          <v-list>
            <template v-for="option in column">
              <v-list-item class="py-0">
                <v-radio color="primary" :label="option.text" :value="option"></v-radio>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </template>
    </v-row>
  </v-radio-group>
</template>

<style scoped>
.v-list {
  &:deep(.v-list-item) {
    min-height: 40px;
    &:deep(.v-list-item__content) {
      height: 40px;
    }
  }
}
</style>
