import { defineStore } from 'pinia'
import type { DocumentStateType, DocumentType } from '@/stores/type/document.type'
import { useShipStore } from '@/stores/ship'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import { useDjangoAuthStore } from '@/stores/authDjango'
import type { ShipType } from '@/stores/type/ship.type'
import { useApi } from '@/api/useApi'
import { useEventsBus } from '@rhdhv/vue-component-library'

const { api } = useApi()

const baseUrl = API.DOCUMENT.BASE
const baseStore = new AppBaseStore<DocumentType>(baseUrl, api)

export const useDocumentStore = defineStore('document', {
  state: (): DocumentStateType => ({
    ...baseStore.appState,
    uploadLoading: false,
    currentUploadIndex: null,
    uploadItems: [],
    downloadLoading: false
  }),
  actions: {
    ...baseStore.appActions,
    async uploadDocumentFiles(shipId: number): Promise<void> {
      this.uploadLoading = true
      const unUploadedItems = this.uploadItems.filter((item) => !item.sent)
      for (const item of unUploadedItems) {
        await this.uploadDocumentFile(item.file, shipId)
      }
      this.uploadLoading = false
    },
    addFiles(files: File[]): void {
      const uploadFiles = files.map((file) => {
        return { file, failed: false, loading: false, sent: false }
      })
      this.uploadItems = [...uploadFiles, ...this.uploadItems]
    },
    removeUnsentFiles(): void {
      this.uploadItems = this.uploadItems.filter((item) => item.sent)
    },
    async downloadDocument(id: number): Promise<void> {
      this.downloadLoading = true
      const url = baseUrl + id + '/download'
      const shipStore = useShipStore()
      await shipStore.download(url)
      this.downloadLoading = false
    },
    async uploadDocumentFile(file: File, shipId: number): Promise<boolean> {
      const index = this.uploadItems.findIndex((item) => item.file === file)
      if (index !== -1) {
        const item = this.uploadItems[index]
        try {
          item.loading = true
          const url = baseUrl + 'upload/'
          const formData = new FormData()
          formData.append('ship', shipId.toString())
          formData.append('file', item.file)
          const response = await api.service(url).post(formData)
          item.sent = true
          item.response = response as DocumentType

          const { emit } = useEventsBus()
          const uploadMessage = {
            status: 200,
            data: [],
            method: 'POST',
            statusText: 'Uploaded document!'
          }
          emit('success', uploadMessage)

          item.loading = false
          return true
        } catch {
          item.failed = true
          item.loading = false
          item.sent = true
          return false
        }
      }
      return false
    }
  },
  getters: {
    documentPermissions:
      () =>
      (ship: ShipType | null): boolean => {
        if (!ship) return false
        const djangoAuthStore = useDjangoAuthStore()
        const isNotApproved = ship.approval_set.every((approval) => !approval.approved)
        return (
          djangoAuthStore.user?.is_superuser ||
          (ship.user === djangoAuthStore.user?.id && isNotApproved)
        )
      }
  }
})
