<script lang="ts" setup>
import type { PedestalType, WinchType } from '@/stores/type/ship.type'
import type { PropType } from 'vue'
import useEventsBus from '@/stores/eventBus'
import type { TableInfo } from '@/types/general'

const props = defineProps({
  items: { type: Object as PropType<WinchType[] | PedestalType[]>, required: true },
  chartEvent: { type: String as PropType<string>, required: true },
  tableHeight: { type: Number, default: 40 },
  tableInfo: { type: Object as PropType<TableInfo>, required: true }
})

const { emit } = useEventsBus()
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-table
      class="overflow-auto"
      density="compact"
      fixed-header
      :height="tableHeight + 'vh'"
      hover
    >
      <thead>
        <tr>
          <th class="border-top-grey" style="width: 5%">#</th>
          <th class="text-center pr-0 border-left-grey border-top-grey" style="width: 35%">
            {{ tableInfo.text }}
          </th>
          <th class="text-center pr-0 border-left-grey border-top-grey" style="width: 20%">
            X [m]
          </th>
          <th class="text-center pr-0 border-left-grey border-top-grey" style="width: 20%">
            Y [m]
          </th>
          <th class="text-center pr-0 border-left-grey border-top-grey" style="width: 20%">
            Z [m]
          </th>
        </tr>
      </thead>
      <tbody v-if="items.length" class="text-center fill-height pb-3">
        <tr v-for="(item, index) in items">
          <td class="pr-0 text-left" style="width: 1%">{{ index + 1 }}</td>
          <td class="pr-0 text-center border-left-grey">
            <v-chip
              v-ripple
              class="clickable"
              :color="tableInfo.color"
              label
              size="x-small"
              @click.stop="emit(chartEvent, { id: item.id, label: tableInfo.label })"
            >
              {{ tableInfo.symbol + (index + 1) }}
            </v-chip>
          </td>
          <td class="pr-0 border-left-grey">{{ item.x.toFixed(1) }}</td>
          <td class="pr-0 border-left-grey">{{ item.y.toFixed(1) }}</td>
          <td class="pr-0 border-left-grey">{{ item.z.toFixed(1) }}</td>
        </tr>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8" style="text-align: center">No data available</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped>
td {
  text-align: center;
}

th {
  text-align: center;
}
</style>
