<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import ShipTableRow from '@/components/ships/ShipTableRow.vue'
import ShipTablePagination from '@/components/ships/ShipTablePagination.vue'
import type { PropType } from 'vue'
import ShipTableHeader from '@/components/ships/ShipTableHeader.vue'
import { computed } from 'vue'
import { useApplicationStore } from '@/stores/application'
import { useShipTypeStore } from '@/stores/shipType'
import { useFairleadStore } from '@/stores/fairlead'
import ActionMenu from '@/components/common/ActionMenu.vue'
import { useDocumentStore } from '@/stores/document'
import type { ShipType } from '@/stores/type/ship.type'
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'

const shipStore = useShipStore()
const documentStore = useDocumentStore()
const {
  items: ships,
  currentItem: currentShip,
  documentDialog,
  paginationServer,
  fetchLoading: shipLoading,
  menuCoordinates,
  menu
} = storeToRefs(shipStore)
const { fetchLoading: appLoading } = storeToRefs(useApplicationStore())
const { fetchLoading: shipTypeLoading } = storeToRefs(useShipTypeStore())
const { fetchLoading: fairleadLoading } = storeToRefs(useFairleadStore())

const props = defineProps({
  chartEvent: { type: String as PropType<string>, required: true }
})

const itemsPerPage = ref(10)
const page = ref(1)

const loading = computed<boolean>(() => {
  return shipLoading.value || appLoading.value || shipTypeLoading.value || fairleadLoading.value
})

async function next(): Promise<void> {
  if ((page.value + 1) * itemsPerPage.value > ships.value.length) {
    await shipStore.fetchPaginationItems()
  }
  page.value += 1
}

function prev(): void {
  page.value -= 1
}

function resetPageNumber(): void {
  page.value = 1
}

const fetchedShips = computed<ShipType[]>(() => {
  const end = page.value * itemsPerPage.value
  return ships.value.slice(0, end)
})

const showedShips = computed<ShipType[]>(() => {
  const start = (page.value - 1) * itemsPerPage.value
  const end = page.value * itemsPerPage.value
  return ships.value.slice(start, end)
})

const uploadDocumentEnabled = computed<boolean>(() => {
  return documentStore.documentPermissions(currentShip.value)
})

const docsDisable = computed<boolean>(() => {
  return !!currentShip.value?.document_set?.length || uploadDocumentEnabled.value
})

function exportAutoCAD(): void {
  if (currentShip.value?.id) {
    shipStore.exportAutoCAD(currentShip.value?.id)
  }
}

function exportExcel(): void {
  if (currentShip.value?.id) {
    shipStore.exportExcel(currentShip.value?.id)
  }
}
</script>

<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="loading" color="primary"></v-progress-linear>
    <div v-else style="height: 4px; width: 100%"></div>
    <v-data-table
      ref="shipTable"
      v-model:items-per-page="itemsPerPage"
      :items="fetchedShips"
      class="overflow-auto"
      height="65vh"
    >
      <template v-slot:headers>
        <ShipTableHeader />
      </template>
      <template v-slot:body>
        <template v-for="item in showedShips" v-if="showedShips.length">
          <ShipTableRow :chart-event="chartEvent" :item="item"></ShipTableRow>
        </template>
        <template v-else>
          <tr>
            <td class="text-center" colspan="14">No items found</td>
          </tr>
        </template>
      </template>
      <template v-slot:bottom></template>
    </v-data-table>
    <ShipTablePagination
      v-if="showedShips.length"
      :count="paginationServer.count"
      :loading="loading"
      :page="page"
      class="text-center mr-4"
      @next="next()"
      @prev="prev()"
      @reset-page="resetPageNumber()"
    />
    <ActionMenu
      :docs-disable="docsDisable"
      :export-auto-cad="currentShip?.has_ship_design"
      :export-excel="currentShip?.has_ship_design || currentShip?.has_armour_param"
      :delete-item="shipStore.deletePermission"
      :model-value="menu"
      :position-x="menuCoordinates.x"
      :position-y="menuCoordinates.y"
      upload
      @toggle="shipStore.toggleMenu()"
      @docDialog="documentDialog = true"
      @export-auto-cad="exportAutoCAD()"
      @export-excel="exportExcel()"
      @delete="shipStore.toggleDeleteDialog(currentShip)"
    ></ActionMenu>
    <DeleteDialog :store="shipStore" :name="currentShip?.name"></DeleteDialog>
  </div>
</template>
