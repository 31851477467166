<script lang="ts" setup>
import {storeToRefs} from "pinia";
import {useShipStore} from "@/stores/ship";
import {useDocumentStore} from "@/stores/document";
import DocumentTableRow from "@/components/document/DocumentTableRow.vue";
import UploadDocumentTableRow from "@/components/document/UploadDocumentTableRow.vue";
import type {DocumentType} from "@/stores/type/document.type";

const props = defineProps({
  tableHeight: {type: Number, default: 20},
  addDocument: {type: Boolean, default: false},
})

const emit = defineEmits(['add'])

const {importSuccess, previewFullShipDialog, importLoading, currentItem: currentShip} = storeToRefs(useShipStore())
const {uploadItems} = storeToRefs(useDocumentStore())

function removeDocument(index: number) {
  uploadItems.value.splice(index, 1)
}

const documents = computed<DocumentType[]>(() => {
  return currentShip.value ? currentShip.value['document_set'] ?? [] : []
})
</script>

<template>
  <v-table
      :height="tableHeight + 'vh'"
      class="overflow-auto"
      density="compact"
      fixed-header
      hover
  >
    <thead>
    <tr>
      <th class="text-center border-right-grey" style="width: 70px">Status</th>
      <th class="text-center">Document</th>
      <th style="width: 50px">
        <v-btn
            v-if="addDocument"
            :disabled="importSuccess || importLoading"
            append-icon="mdi-plus"
            color="primary"
            density="compact"
            small
            variant="flat"
            @click="emit('add')"
        >Add
        </v-btn>
      </th>
    </tr>
    </thead>
    <tbody>
    <template v-for="(item, index) in uploadItems" v-if="uploadItems.length">
        <DocumentTableRow v-if="item.response" :document="item.response"></DocumentTableRow>
        <UploadDocumentTableRow v-else :document="item" @remove="removeDocument(index)"></UploadDocumentTableRow>
    </template>
    <template v-if="documents.length && !previewFullShipDialog" v-for="item in documents">
        <DocumentTableRow :document="item"></DocumentTableRow>
      </template>
    <tr v-if="!(uploadItems.length || documents.length)">
      <td colspan="3" style="height: 40px; text-align: center">No document</td>
    </tr>
    <tr>
      <td colspan="48" style="height: 3px"></td>
    </tr>
    </tbody>
  </v-table>
</template>