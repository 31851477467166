<script lang="ts" setup>
const props = defineProps({
  count: { type: Number, required: true },
  loading: { type: Boolean, required: false, default: false },
  page: { type: Number, required: true }
})

const itemsPerPage = ref(10)

function prev(): void {
  emit('prev')
}

function next(): void {
  emit('next')
}

const amountOfItems = computed<number>(() => {
  return props.count
})

watch(amountOfItems, () => {
  emit('resetPage')
})

const info = computed<string>(() => {
  const start = (props.page - 1) * itemsPerPage.value + 1
  const end =
    props.page * itemsPerPage.value >= props.count ? props.count : props.page * itemsPerPage.value
  return `${start}-${end} of ${props.count}`
})

const nextDisabled = computed<boolean>(() => {
  return props.page * itemsPerPage.value >= props.count
})

const prevDisabled = computed<boolean>(() => {
  return props.page === 1
})

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'prev'): void
  (e: 'resetPage'): void
}>()
</script>

<template>
  <div style="float: right">
    <span style="font-size: 12px"> {{ info }}</span>
    <v-btn
      :disabled="prevDisabled || loading"
      class="ml-8"
      density="comfortable"
      icon="mdi-chevron-left"
      variant="text"
      @click="prev()"
    ></v-btn>
    <v-btn
      :disabled="nextDisabled || loading"
      class="ml-4"
      density="comfortable"
      icon="mdi-chevron-right"
      variant="text"
      @click="next()"
    ></v-btn>
  </div>
</template>
