import { defineStore } from 'pinia'

import { useShipStore } from '@/stores/ship'
import API from '@/api/apiUrls'
import type { djangoAuthStateType, UserType } from '@/stores/type/user.type'
import { useApi } from '@/api/useApi'

const { api } = useApi()
const auth = API.TOKEN.ACCESS
const user = API.USER.CURRENT_USER

// TODO transfer to typescript
export const useDjangoAuthStore = defineStore('auth', {
  state: (): djangoAuthStateType => ({
    user: null,
    isInitDone: true,
    loading: false,
    rhdhvLoading: false
  }),
  actions: {
    async authenticate({
      strategy,
      email,
      password
    }: {
      strategy: string
      email: string
      password: string
    }): Promise<{ user: UserType }> {
      this.loading = true
      try {
        const response = await api.service(auth).post({ username: email, password: password })
        const accessToken = response.access
        const refreshToken = response.refresh
        localStorage.setItem('access', accessToken)
        localStorage.setItem('refresh', refreshToken)
      } catch (e) {
        this.loading = false
        throw e
      }
      const userResponse = await api.service(user).get()
      this.user = userResponse
      this.loading = false
      return { user: userResponse }
    },
    async reAuthenticate(): Promise<{ user: UserType | null }> {
      this.loading = true
      const accessToken = localStorage.getItem('access')
      if (accessToken) {
        try {
          const userResponse = await api.service(user).get()
          this.loading = false
          return { user: userResponse }
        } catch (e) {
          this.loading = false
          return { user: null }
        }
      }
      this.loading = false
      return { user: null }
    },
    async getPromise() {},
    async tryFetchUser(): Promise<void> {
      this.loading = true
      const token = localStorage.getItem('access')
      if (token && token !== 'null') {
        try {
          const response = await api.service(user).get()
          if (response) {
            this.user = response as UserType
          }
          this.isInitDone = true
        } catch (error) {
          this.isInitDone = false
          console.log(error)
        }
      }
      this.loading = false
    },
    async logout(): Promise<void> {
      const shipStore = useShipStore()

      shipStore.resetState()
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      this.user = null
    },
    toggleRHDHVLoading() {
      this.rhdhvLoading = !this.rhdhvLoading
    },
    hasServicePermission(serviceName, permission) {
      return true
    },
    hasObjectPermission(object, permission) {
      return true
    }
  }
})
