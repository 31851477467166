<script lang="ts" setup>
import {
  Chart as ChartJS,
  ChartTypeRegistry,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { Scatter } from 'vue-chartjs'
import zoomPlugin from 'chartjs-plugin-zoom'
import { computed } from 'vue'
import type { PropType } from 'vue'

import type {
  ShipType,
  FairleadType,
  ShipContourType,
  WinchType,
  PedestalType
} from '@/stores/type/ship.type'
import * as chartShipConfig from './chartShipConfig'
import useEventsBus from '@/stores/eventBus'
import type { BaseDataset } from '@/types/chartTypes'
import {
  CONTOUR_COLOR,
  FAIRLEAD_COLOR,
  FAIRLEAD_LABEL,
  PEDESTAL_COLOR,
  PEDESTAL_LABEL,
  WINCH_COLOR,
  WINCH_LABEL
} from '@/components/constantVariables'
import ChartControls from '@/components/chart/ChartControls.vue'

const props = defineProps({
  ship: { type: [Object, null] as PropType<ShipType | null>, required: true },
  chartEvent: { type: String as PropType<string>, required: true },
  chartRef: { type: String as PropType<string>, required: true }
})

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, zoomPlugin)

const { bus } = useEventsBus()

const chart = ref<ChartTypeRegistry['scatter']>()

const shipContourItems = computed<ShipContourType[]>(() => {
  return props.ship ? props.ship.shipcontour_set?.sort((a, b) => a.id - b.id) : []
})

const winchItems = computed<WinchType[]>(() => {
  return props.ship ? props.ship.winch_set : []
})

const pedestalItems = computed<PedestalType[]>(() => {
  return props.ship ? props.ship.pedestal_set : []
})

const fairleadItems = computed<FairleadType[]>(() => {
  return props.ship ? props.ship.fairlead_set : []
})

const dataSets = computed<Object>(() => {
  let dataSets: Object[] = []
  if (shipContourItems.value) {
    dataSets.push({
      label: 'Ship Contour',
      hover: {
        mode: false
      },
      pointRadius: 1,
      borderWidth: 1,
      hoverRadius: 1,
      hoverBorderWidth: 1,
      showLine: true,
      borderColor: CONTOUR_COLOR,
      backgroundColor: CONTOUR_COLOR,
      pointBackgroundColor: CONTOUR_COLOR,
      data: shipContourItems.value.map((item) => {
        return { x: item.x, y: item.y }
      })
    })
  }
  if (fairleadItems.value) {
    dataSets.push({
      label: FAIRLEAD_LABEL,
      fill: false,
      pointStyle: 'rectRot',
      radius: 4,
      borderColor: FAIRLEAD_COLOR,
      backgroundColor: FAIRLEAD_COLOR,
      pointBackgroundColor: FAIRLEAD_COLOR,
      data: fairleadItems.value.map((item, index) => {
        return { index: index, id: item.id, x: item.x, y: item.y, z: item.z }
      })
    })
  }
  if (winchItems.value) {
    dataSets.push({
      label: WINCH_LABEL,
      fill: false,
      pointStyle: 'triangle',
      radius: 4,
      borderColor: WINCH_COLOR,
      backgroundColor: WINCH_COLOR,
      pointBackgroundColor: WINCH_COLOR,
      data: winchItems.value.map((item, index) => {
        return { index: index, id: item.id, x: item.x, y: item.y, z: item.z }
      })
    })
  }
  if (pedestalItems.value) {
    dataSets.push({
      label: PEDESTAL_LABEL,
      fill: false,
      borderColor: PEDESTAL_COLOR,
      backgroundColor: PEDESTAL_COLOR,
      pointBackgroundColor: PEDESTAL_COLOR,
      data: pedestalItems.value.map((item, index) => {
        return { index: index, id: item.id, x: item.x, y: item.y, z: item.z }
      })
    })
  }
  return { datasets: dataSets }
})

const options = computed(() => {
  const baseScales = { ...chartShipConfig.options.scales }
  const x = {
    ...chartShipConfig.options.scales.x,
    min: -chartScales.value,
    max: chartScales.value,
    stepSize: 10
  }
  const y = {
    ...chartShipConfig.options.scales.y,
    min: -chartScales.value,
    max: chartScales.value,
    stepSize: 10
  }
  const scales = { ...baseScales, x, y }
  return { ...chartShipConfig.options, scales }
})

const chartScales = computed<number>(() => {
  const loa = props.ship ? props.ship.length_overall : 0
  return loa ? Math.ceil(loa / 2) * 1.05 : 0
})

watch(
  () => bus.value.get(props.chartEvent),
  (val: [{ id: number; label: string }]) => {
    const [item] = val
    triggerMouseEvent(item)
  }
)

function triggerMouseEvent({ id, label }: { id: number; label: string }) {
  const chartJs = ChartJS.getChart(props.chartRef)
  let index = chartJs ? chartJs.data.datasets.findIndex((item) => item.label === label) : -1
  const findResult = chartJs?.data.datasets.find((item) => item.label === label)
  let itemIndex = -1
  if (label === FAIRLEAD_LABEL) {
    const data: BaseDataset | null = findResult ? (findResult as BaseDataset) : null
    itemIndex = data ? data.data.findIndex((item) => id === item?.id) : -1
  } else if (label === WINCH_LABEL) {
    const data: BaseDataset | null = findResult ? (findResult as BaseDataset) : null
    itemIndex = data ? data.data.findIndex((item) => id === item?.id) : -1
  } else if (label === PEDESTAL_LABEL) {
    const data: BaseDataset | null = findResult ? (findResult as BaseDataset) : null
    itemIndex = data ? data.data.findIndex((item) => id === item?.id) : -1
  }
  let meta = chartJs?.getDatasetMeta(index),
    rect = chartJs?.canvas.getBoundingClientRect(),
    point = meta?.data[itemIndex]
  if (rect && point) {
    let evt = new MouseEvent('mousemove', {
        clientX: rect.left + point?.x,
        clientY: rect.top + point.y
      }),
      node = chartJs?.canvas
    node?.dispatchEvent(evt)
  }
}

function resetZoom() {
  const chartJs = ChartJS.getChart(props.chartRef)
  if (chartJs) {
    chartJs.resetZoom()
  }
}

const backgroundColorPlugin = {
  id: 'background_color_plugin',
  beforeDraw: (chart: ChartJS, args: any, options: any) => {
    const {
      ctx,
      chartArea: { top, right, bottom, left, width, height },
      scales: { x, y }
    } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = 'white'
    ctx.fillRect(left, top, width, height)
    ctx.restore()
  }
}
</script>

<template>
  <div class="fill-height">
    <template v-if="shipContourItems.length">
      <ChartControls @resetZoom="resetZoom()"></ChartControls>
      <Scatter
        :id="chartRef"
        :ref="chartRef"
        :data="dataSets"
        :options="options"
        :plugins="[backgroundColorPlugin]"
      />
    </template>
    <template v-else>
      <div class="fill-height" style="align-content: center; text-align: center">
        <span>No plan view of ship</span>
      </div>
    </template>
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
