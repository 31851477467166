<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'
import { useDocumentStore } from '@/stores/document'
import { storeToRefs } from 'pinia'
import ShipPreview from '@/components/import/ShipPreview.vue'

const shipStore = useShipStore()
const { items: ships, previewFullShipDialog, importLoading, importSuccess } = storeToRefs(shipStore)
const { uploadLoading: uploadDocumentLoading } = storeToRefs(useDocumentStore())

const emit = defineEmits(['import', 'back', 'close', 'finish'])
</script>

<template>
  <v-dialog
    v-model="previewFullShipDialog"
    :scrim="false"
    fullscreen
    persistent
    style="background-color: white"
    transition="dialog-bottom-transition"
  >
    <v-toolbar color="primary" dark>
      <v-btn
        :disabled="importLoading || uploadDocumentLoading"
        dark
        icon
        @click="importSuccess ? emit('finish') : emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Preview</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <ShipPreview style="height: 85vh; flex: unset"></ShipPreview>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <template v-if="!importSuccess">
          <v-btn
            :disabled="importLoading || uploadDocumentLoading"
            class="mt-2"
            color="primary"
            variant="flat"
            @click="emit('back')"
            >Back
          </v-btn>
          <v-btn
            :loading="importLoading || uploadDocumentLoading"
            center
            class="ml-2 mt-2"
            color="primary"
            small
            variant="flat"
            @click="emit('import')"
          >
            Import
          </v-btn>
        </template>
        <v-btn
          v-else
          :loading="uploadDocumentLoading"
          center
          class="ml-2 mt-2"
          color="primary"
          small
          variant="flat"
          @click="emit('finish')"
          >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
