import { defineStore } from 'pinia'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import type { ShiptypeStateType, ShiptypeType } from '@/stores/type/shipType.type'
import { useApi } from '@/api/useApi'

const { api } = useApi()

const baseUrl = API.SHIP_TYPE.BASE
const baseStore = new AppBaseStore<ShiptypeType>(baseUrl, api)

export const useShipTypeStore = defineStore('shipType', {
  state: (): ShiptypeStateType => ({
    ...baseStore.appState
  }),
  actions: {
    ...baseStore.appActions
  }
})
