<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import DocumentTable from '@/components/document/DocumentTable.vue'
import { useDocumentStore } from '@/stores/document'

const documentStore = useDocumentStore()
const shipStore = useShipStore()
const { documentDialog, currentItem: currentShip } = storeToRefs(useShipStore())
const { uploadItems, uploadLoading } = storeToRefs(useDocumentStore())

const documentFiles = ref<File[]>([])
const addDocument = ref<boolean>(false)

watch(addDocument, () => {
  if (!addDocument.value) {
    documentFiles.value = []
    documentStore.removeUnsentFiles()
  }
})

const uploadDisabled = computed<boolean>(() => {
  return uploadItems.value.every((item) => item.sent)
})

function add() {
  documentStore.addFiles(documentFiles.value)
  documentFiles.value = []
}

function close() {
  addDocument.value = false
  emit('close')
}

const emit = defineEmits(['upload', 'close'])
</script>

<template>
  <v-dialog v-model="documentDialog" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline"> Document </span>
        <v-btn
          center
          color="primary"
          icon="mdi-close"
          small
          style="float: right"
          variant="text"
          :disabled="uploadLoading"
          @click="close()"
        />
        <v-btn
          v-if="documentStore.documentPermissions(currentShip) && !addDocument"
          prepend-icon="mdi-plus"
          center
          color="primary"
          small
          style="float: right; margin: 6px 0"
          variant="flat"
          :disabled="uploadLoading"
          @click="addDocument = true"
        >
          Add
        </v-btn>
      </v-card-title>
      <v-row class="mx-3 pb-3" no-gutters>
        <template v-if="addDocument">
          <v-col class="pr-3" cols="10">
            <v-file-input
              v-model="documentFiles"
              :disabled="uploadLoading"
              chips
              color="primary"
              density="compact"
              hide-details
              label="Upload document"
              multiple
              truncate-length="20"
            ></v-file-input>
          </v-col>
          <v-col cols="2" style="display: flex; align-items: center">
            <v-btn
              :disabled="uploadLoading || !documentFiles.length"
              append-icon="mdi-plus"
              block
              center
              color="primary"
              small
              variant="flat"
              @click="add"
            >
              Add
            </v-btn>
          </v-col>
        </template>
        <v-col class="mt-3" cols="12">
          <v-divider></v-divider>
          <DocumentTable :table-height="40" class="mb-2"></DocumentTable>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions v-if="addDocument" class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="documentStore.documentPermissions(currentShip)"
          :disabled="uploadLoading"
          center
          color="primary"
          small
          variant="flat"
          @click="addDocument = false"
          >Cancel
        </v-btn>
        <v-btn
          :disabled="uploadDisabled"
          :loading="uploadLoading"
          append-icon="mdi-cloud-upload"
          color="primary"
          variant="flat"
          @click="emit('upload')"
          >Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
