import { defineStore } from 'pinia'
import type { ApplicationStateType, ApplicationType } from '@/stores/type/application.type'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import { useApi } from '@/api/useApi'

const { api } = useApi()

const baseUrl = API.APPLICATION.BASE
const baseStore = new AppBaseStore<ApplicationType>(baseUrl, api)

export const useApplicationStore = defineStore('application', {
  state: (): ApplicationStateType => ({
    ...baseStore.appState
  }),
  actions: {
    ...baseStore.appActions,
    resetState(): void {
      this.currentItem = null
      this.items = []
    }
  }
})
