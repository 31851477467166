<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'

const shipStore = useShipStore()
</script>

<template>
  <v-btn
    append-icon="mdi-download"
    class="fill-height"
    color="primary"
    variant="flat"
    @click="shipStore.downloadTemplate()"
    style="min-height: 40px; float: right"
  >
    Template
  </v-btn>
</template>
