<script setup lang="ts">
const snackbar = ref<boolean>(false)
const timeout = ref<Number>(2000)

const emit = defineEmits(['resetZoom'])

function copyImageToClipboard() {
  let image = document.getElementById("chart") as HTMLCanvasElement;
  image.toBlob((blob) => {
        if (blob) {
          navigator.clipboard.write([new ClipboardItem({"image/png": blob})])
        }
      }
  );
  snackbar.value = true;
}
</script>

<template>
  <div style="position: absolute" class="ma-3">
    <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
            class="mr-0"
            v-bind="props"
            icon="mdi-arrow-expand-all"
            flat
            @click="emit('resetZoom')"
        >
        </v-btn>
      </template>
      <span>Reset graph to origin location and zoom location</span>
    </v-tooltip>
    <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
            class="mr-0"
            v-bind="props"
            icon="mdi-content-copy"
            flat
            @click="copyImageToClipboard()"
        >
        </v-btn>
      </template>
      <span>Copy the graph image to Clipboard</span>
    </v-tooltip>
        <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
            class="mr-0"
            v-bind="props"
            icon="mdi-help-circle"
            flat
            @click="emit('resetZoom')"
        >
        </v-btn>
      </template>
      <span>Click + drag to change the viewport, CTRL + scroll to zoom</span>
    </v-tooltip>
    </div>
  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="success"
  >
    Copied to clipboard!
    <template v-slot:actions>
      <v-btn
           icon="mdi-close"
          size="small"
          @click="snackbar = false"
      >

      </v-btn>
    </template>
  </v-snackbar>
</template>
