<script lang="ts" setup>
import type { StoreGeneric } from 'pinia'

const emit = defineEmits()

const props = defineProps({
  name: {
    type: String,
    required: false
  },
  message: {
    type: String,
    required: false,
    default: 'Delete <strong>{name}</strong>?'
  },
  store: {
    type: Object as StoreGeneric,
    required: true
  }
})

const { deleteDialog, deleteLoading, currentItem } = storeToRefs(props.store)

async function deleteItem() {
  const deletedItem = await props.store.delete()
  if (deletedItem) {
    props.store.toggleDeleteDialog()
    emit('deleted', deletedItem)
  }
}

const message = computed(() => {
  let showName = ''
  if (!props.name) {
    if (currentItem.value.name) {
      showName = currentItem.value.name
    }
  } else {
    showName = props.name
  }

  return props.message.replace(/{name}/g, showName)
})
</script>

<template>
  <v-dialog v-model="deleteDialog" max-width="400" @click:outside="store.toggleDeleteDialog()">
    <v-card class="pa-3">
      <v-card-title>
        <span>Delete</span>
      </v-card-title>
      <v-card-text class="pt-3 pl-4" v-html="message"></v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn :loading="deleteLoading" color="red darken-1" text @click="deleteItem()">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
