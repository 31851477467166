import { createApp } from 'vue'
import { pinia } from './modules/pinia.ts'
import vuetify from './plugins/vuetify'
import router from './router'
import * as Sentry from '@sentry/vue'
import 'vuetify/dist/vuetify.min.css'

import { AuthenticationManagement } from '@rhdhv/vue-authentication-middleware'
import { ComponentLibrary, useEventsBus } from '@rhdhv/vue-component-library'

import App from './App.vue'
import { useDjangoAuthStore } from './stores/authDjango'
import { useSnackbarStore } from './stores/snackbarStore'

import HeaderImage from '@/assets/header.jpg'
import HeaderImageLazy from '@/assets/header_lazy.jpg'
import { useApi } from '@/api/useApi'

const { api } = useApi()
const { bus } = useEventsBus()

const app = createApp(App).use(vuetify).use(pinia).use(router)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  tracePropagationTargets: ['*'],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in cloud and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const authStore = useDjangoAuthStore(pinia)
app
  .use(AuthenticationManagement, {
    appTitle: 'SHIP',
    headerImage: HeaderImage,
    headerImageLazy: HeaderImageLazy,
    feathers: api,
    showForgotPassword: false,
    snackbarStore: useSnackbarStore(pinia),
    authStore: authStore,
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL
  })
  .provide('useAuthStore', authStore)

app.use(ComponentLibrary, { externalBus: bus })

app.mount('#app')
