<script lang="ts" setup>
import { useApplicationStore } from '@/stores/application'
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import CheckBoxField from '@/components/common/CheckBoxField.vue'
import { useApprovalStore } from '@/stores/approval'
import { useDjangoAuthStore } from '@/stores/authDjango'
import type { ShipType } from '@/stores/type/ship.type'
import type { ApprovalType } from '@/stores/type/approval.type'

const props = defineProps({
  ship: { type: Object as PropType<ShipType>, required: true }
})

const approvalStore = useApprovalStore()

const { items: apps } = storeToRefs(useApplicationStore())
const { user } = storeToRefs(useDjangoAuthStore())

const approvalItems = computed<ApprovalType[]>(() => {
  return props.ship?.approval_set ?? []
})

const disabled = computed<boolean>(() => {
  return !user.value?.is_superuser
})

function applicationName(applicationId: number): string {
  return apps.value.find((app) => app.id === applicationId)?.name ?? ''
}

function updateApproval(value: boolean, approval: ApprovalType) {
  approval.approved = value
  approvalStore.update(approval)
}

const emit = defineEmits<{ (e: 'click'): void }>()
</script>

<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn icon="mdi-list-status" v-bind="props" variant="text" @click="emit('click')"> </v-btn>
    </template>
    <v-card width="auto">
      <v-list>
        <template v-for="approval in approvalItems">
          <v-list-item class="pl-2">
            <v-list-item-title>
              <CheckBoxField
                :disabled="disabled"
                :item="approval"
                :label="applicationName(approval.application)"
                parameter="approved"
                @set="(value: boolean) => updateApproval(value, approval)"
              />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>
